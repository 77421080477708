document.addEventListener("DOMContentLoaded", () => {
  const tablist = document.querySelectorAll("[role='tablist']");

  tablist.forEach((list) => {
    const tabs = Array.from(list.querySelectorAll("[data-toggle='tab']"));
    const targets = tabs.map((tab) =>
      document.querySelector(tab.dataset.target)
    );

    tabs.forEach((tab, index) => {
      tab.addEventListener("click", (e) => {
        e.preventDefault();

        tabs.forEach((t) => t.classList.remove("is-active"));
        targets.forEach((t) => t && t.classList.remove("is-active"));

        tab.classList.add("is-active");
        if (targets[index]) {
          targets[index].classList.add("is-active");
        } else {
          console.error(`No target found for tab ${tab.id}`);
        }
      });
    });
  });
});
