document.addEventListener("DOMContentLoaded", () => {
  const hamburger = document.querySelector(".js-hamburger");
  const menu = document.querySelector(".js-sidebar-menu");

  hamburger.addEventListener("click", () => {
    hamburger.querySelector("button").classList.toggle("is-open");

    const expanded = menu.getAttribute("aria-expanded") === "true" || false;
    menu.setAttribute("aria-expanded", !expanded);
  });
});
