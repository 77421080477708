document.addEventListener("DOMContentLoaded", () => {
  const megaMenu = document.querySelector(".js-mega-menu");
  const items = megaMenu.querySelectorAll("[data-mega-menu-ref]");

  items.forEach((item, index) => {
    const submenu = item.querySelector(".js-submenu");

    // 滑鼠移入主選單時展開子選單
    item.addEventListener("mouseenter", () => {
      closeAllSubmenus();

      openSubmenu(item, submenu);
    });

    item.addEventListener("keydown", (e) => {
      switch (e.key) {
        case "Enter":
          // 如果是 Enter 鍵，則打開子選單
          closeAllSubmenus();
          if (submenu) {
            openSubmenu(item, submenu);
          }
          break;
        default:
          break;
      }
    });
  });
});

function closeAllSubmenus() {
  const submenus = document.querySelectorAll(".js-submenu");

  submenus.forEach((submenu) => {
    submenu.removeAttribute("data-mega-menu-show");
  });
}

function openSubmenu(parent, child) {
  if (!child) {
    return;
  }

  child.setAttribute("data-mega-menu-show", "");

  child.addEventListener("mouseleave", (e) => {
    if (!parent.contains(e.relatedTarget)) {
      child.removeAttribute("data-mega-menu-show");
    }
  });
}
