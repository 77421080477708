document.addEventListener("DOMContentLoaded", () => {
  const toggles = document.querySelectorAll("[data-toggle='collapse']");

  toggles.forEach((toggle) => {
    const targetSelector = toggle.dataset.target;
    const targets = document.querySelectorAll(targetSelector);

    toggle.addEventListener("click", (e) => {
      e.preventDefault();

      const isExpanded = toggle.getAttribute("aria-expanded") === "true";
      toggle.setAttribute("aria-expanded", !isExpanded);

      targets.forEach((target) => {
        target.setAttribute("aria-hidden", isExpanded);
      });
    });
  });
});
